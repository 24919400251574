////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(0, 0, 0);
}
.bg-secondaryColor {
  background-color: rgb(229, 229, 229);
}
.bg-thirdColor {
  background-color: rgb(229, 229, 229);
}
.bg-fourthColor {
  background-color: rgb(255, 0, 126);
}
.bg-fifthColor {
  background-color: rgb(87, 87, 87);
}
.bg-sixthColor {
  background-color: rgb(0, 0, 0);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
